<template>
    <div style="text-align: left;">
        <el-card shadow="never">
            <div class="pageCenter apply-container">

                <template
                        v-if="
                                enterpriseAuthenticationStatus!=null
                                &&
                                (
                                    enterpriseAuthenticationStatus === $oucy.enterpriseAuthenticationStatus.WAITING_FOR_APPLY
                                    ||
                                    enterpriseAuthenticationStatus === $oucy.enterpriseAuthenticationStatus.APPLY_FAIL
                                    ||
                                    enterpriseAuthenticationStatus === $oucy.enterpriseAuthenticationStatus.APPLY_SUCCESS
                                )
                ">
                    <el-form :model="identityApplyForm" :rules="identityApplyFormRules" ref="identityApplyForm"
                             label-width="120px">
                        <el-form-item>
                            <h1>企业身份认证</h1>
                        </el-form-item>
                        <el-form-item label="公司名称" prop="authenticationWorkCompany">
                            <el-input v-model="identityApplyForm.enterpriseName" disabled />
                        </el-form-item>
                        <el-form-item label="统一信用代码" prop="authenticationWorkCompany">
                            <el-input v-model="identityApplyForm.enterpriseIdCard" disabled />
                        </el-form-item>
                        <el-form-item label="营业执照" prop="authenticationLicensePhoto">
                            必须为彩色图片且小于6mb，文件格式为：bmp,png,jpeg,jpg或gif
                            <el-button type="text">查看示例</el-button>
                            <el-upload
                                    accept="image/jpeg,image/gif,image/png"
                                    :before-upload="handleOnBeforeUploadAndSquare"
                                    class="avatar-uploader"
                                    action="fakeaction"
                                    :show-file-list="false"
                                    :http-request="handleLicensePhotoUpload">
                                <img v-if="identityApplyForm.authenticationLicensePhoto"
                                     :src="this.$oucy.ossUrl+identityApplyForm.authenticationLicensePhoto"
                                     class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="法人姓名" prop="authenticationLegalPersonName">
                            <el-input v-model="identityApplyForm.authenticationLegalPersonName" />
                        </el-form-item>
                        <el-form-item label="身份证号" prop="authenticationLegalPersonIdCard">
                            <el-input v-model="identityApplyForm.authenticationLegalPersonIdCard" />
                        </el-form-item>
                        <el-form-item label="身份证照片" prop="idCardPhoto">
                            <el-upload
                                    accept="image/jpeg,image/gif,image/png"
                                    :before-upload="handleOnBeforeUploadAndSquare"
                                    class="avatar-uploader"
                                    action="fakeaction"
                                    :show-file-list="false"
                                    :http-request="handlePositiveImgUpload"
                                    :disabled="isApplySuccess">
                                <img v-if="identityApplyForm.authenticationLegalPersonIdcardPhotoPositive"
                                     :src="this.$oucy.ossUrl+identityApplyForm.authenticationLegalPersonIdcardPhotoPositive"
                                     class="avatar">
                                <div v-else class="avatar-uploader-icon avatar-uploader-icon-positive"></div>
                            </el-upload>
                            <el-upload
                                    accept="image/jpeg,image/gif,image/png"
                                    :before-upload="handleOnBeforeUploadAndSquare"
                                    class="avatar-uploader"
                                    action="fakeaction"
                                    :show-file-list="false"
                                    :http-request="handleReverseImgUpload"
                                    :disabled="isApplySuccess">
                                <img v-if="identityApplyForm.authenticationLegalPersonIdcardPhotoReverse"
                                     :src="this.$oucy.ossUrl+identityApplyForm.authenticationLegalPersonIdcardPhotoReverse"
                                     class="avatar">
                                <div v-else class="avatar-uploader-icon avatar-uploader-icon-reverse"></div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item v-if="!isApplySuccess">
                            <el-button @click="keywordFiltr('submitIdentifyApply')" type="primary">申 请 认 证</el-button>
                            <el-button @click="$oucy.replace('/enter/', null)">取 消 返 回</el-button>
                        </el-form-item>
                    </el-form>
                </template>


                <template
                        v-if="
                                enterpriseAuthenticationStatus!=null
                                &&
                                (
                                    enterpriseAuthenticationStatus === $oucy.buyerAuthenticationStatus.APPLYING
                                )
                ">
                    <msgResult
                            :msg-type="4"
                            title="企业身份认证审核中"
                            desc="尊敬的用户，你提交的「企业身份认证」申请工作人员正在审核中，结果将通过短信及站内信进行通知！"
                            :buttons="applyButtons"
                    />
                </template>

            </div>
        </el-card>
    </div>
</template>

<script>
    import oss from "@/util/oss.js"
    import oucy from "../../../util/oucyUtil";
    import msgResult from "../../../components/MsgResult"
    import {localSet, localGet, localDel} from "@/store/store"

    export default {
        name: "Identify",
        components:{
            msgResult
        },
        data(){
            const validateIdCardPhoto = (rule, value, callback) => {
                const me = this;
                if (!me.identityApplyForm.authenticationLegalPersonIdcardPhotoPositive) {
                    callback(new Error('请上传身份证[正面]照片'));
                } else if (!me.identityApplyForm.authenticationLegalPersonIdcardPhotoReverse) {
                    callback(new Error('请上传身份证[反面]照片'));
                } else {
                    callback();
                }
            };
            return{
                isApplySuccess:false,
                enterpriseAuthenticationStatus:null,
                identityApplyForm:{
                    enterpriseName:null,
                    enterpriseIdCard:null,
                    authenticationLicensePhoto:null,
                    authenticationLegalPersonName:null,
                    authenticationLegalPersonIdCard:null,
                    authenticationLegalPersonIdcardPhotoPositive:null,
                    authenticationLegalPersonIdcardPhotoReverse:null
                },
                identityApplyFormRules:{
                    authenticationLicensePhoto: [
                        {required: true, message: '请上传营业执照', trigger: 'blur'},
                    ],
                    authenticationLegalPersonName: [
                        {required: true, message: '请填写法人姓名', trigger: 'blur'},
                    ],
                    authenticationLegalPersonIdCard: [
                        {required: true, message: '请填写身份证号', trigger: 'blur'},
                    ],
                    idCardPhoto: [
                        {validator: validateIdCardPhoto, trigger: 'change'},
                    ]
                },
                applyButtons:[{
                    name:'企业管理中心',
                    type:'default',
                    action: function () {
                        oucy.replace('/enter');
                    }
                }],
                enterpriseId:null,
            }
        },
        mounted() {
            const me = this;
            let enterprise=localGet('enterprise')||{}
            this.enterpriseId=enterprise.id
            oucy.queryEnterpriseIdentifyStatus({enterpriseId:this.enterpriseId}).then(res=>{
                me.enterpriseAuthenticationStatus = res;
                return oucy.queryEnterApplyInfo({enterpriseId:this.enterpriseId});
            }).then(res=>{
                console.log(res);
                me.identityApplyForm.enterpriseName = res.enterpriseName;
                me.identityApplyForm.enterpriseIdCard = res.enterpriseCode;
                if(res.enterpriseAuthenticationDto){
                   let ltRealNameAuthenticationDto= res.enterpriseAuthenticationDto
                   this.identityApplyForm.authenticationLegalPersonName=ltRealNameAuthenticationDto.authenticationLegalPersonName
                   this.identityApplyForm.authenticationLicensePhoto=ltRealNameAuthenticationDto.authenticationLicensePhoto
                   this.identityApplyForm.authenticationLegalPersonIdCard=ltRealNameAuthenticationDto.authenticationLegalPersonIdCard
                   this.identityApplyForm.authenticationLegalPersonIdcardPhotoPositive=ltRealNameAuthenticationDto.authenticationLegalPersonIdcardPhotoPositive
                   this.identityApplyForm.authenticationLegalPersonIdcardPhotoReverse=ltRealNameAuthenticationDto.authenticationLegalPersonIdcardPhotoReverse
                }
            });
        },
        methods:{
            keywordFiltr(fn){
                this.$oucy.keywordFiltr(this.identityApplyForm).then(res=>{
                   this[fn]() 
                },err=>{})
            },
            handleOnBeforeUploadAndSquare: function(file){
                const me = this;
                return new Promise((resolve, reject) => {
                    me.$oucy.checkImgFile(file).then(res=>{
                        return me.$oucy.checkImgSquare(res, 0, 0);
                    }).then(res=>{
                        resolve(res);
                    }).catch(err=>{
                        reject(err);
                    });
                });

            },
            handleLicensePhotoUpload: function(params) {
                const me = this;
                const file = params.file;
                me.$oucy.checkImgFile(file).then(res=>{
                    let Oss=new oss()
                    return Oss.startUpload(file)
                    // return me.$oucy.uploadRequest(file);
                }).then(res=>{
                    me.identityApplyForm.authenticationLicensePhoto = res;
                });
            },
            handlePositiveImgUpload: function (params) {
                const me = this;
                const file = params.file;
                me.$oucy.checkImgFile(file).then(res => {
                    let Oss=new oss()
                    return Oss.startUpload(file)
                    // return me.$oucy.uploadRequest(file);
                }).then(res => {
                    me.identityApplyForm.authenticationLegalPersonIdcardPhotoPositive = res;
                });
            },
            handleReverseImgUpload: function (params) {
                const me = this;
                const file = params.file;
                me.$oucy.checkImgFile(file).then(res => {
                    let Oss=new oss()
                    return Oss.startUpload(file)
                    // return me.$oucy.uploadRequest(file);
                }).then(res => {
                    me.identityApplyForm.authenticationLegalPersonIdcardPhotoReverse = res;
                });
            },
            submitIdentifyApply:function () {
                const me = this;
                me.$refs["identityApplyForm"].validate((valid) => {
                    if (valid) {
                        oucy.postRequest('/client/enterprise/enterpriseAuthentication/applyEnterpriseAuthentication', {
                            enterpriseId:me.enterpriseId,
                            authenticationLicensePhoto: me.identityApplyForm.authenticationLicensePhoto,
                            authenticationLegalPersonName: me.identityApplyForm.authenticationLegalPersonName,
                            authenticationLegalPersonIdCard: me.identityApplyForm.authenticationLegalPersonIdCard,
                            authenticationLegalPersonIdcardPhotoPositive: me.identityApplyForm.authenticationLegalPersonIdcardPhotoPositive,
                            authenticationLegalPersonIdcardPhotoReverse: me.identityApplyForm.authenticationLegalPersonIdcardPhotoReverse
                        }).then(res => {
                            return oucy.queryEnterpriseIdentifyStatus({enterpriseId:this.enterpriseId});
                        }).then(res => {
                            me.enterpriseAuthenticationStatus = res;
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .apply-container {
        width: 650px;
        min-height: 600px;
        padding: 0 0 50px 0;
    }
    .avatar-uploader >>> .el-upload {
        border: 1px #d9d9d9 dashed;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        float: left;
        margin: 0 8px 8px 0;
    }
    .avatar-uploader >>> .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar{
        width: 246px;
        height: 146px;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 246px;
        height: 146px;
        line-height: 146px;
        text-align: center;
    }

    .avatar-uploader-icon-positive {
        background-image: url("../../../assets/idcard_positive.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .avatar-uploader-icon-reverse {
        background-image: url("../../../assets/idcard_reverse.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
</style>
